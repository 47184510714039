<template lang="pug">
  div()
    .panel.panel-success()
      .panel-heading
        strong {{ $root.lang.sendViaMail }}
      form.panel-body(@submit.prevent="send" v-if="!sendSuccess")
        .form-group
          label {{ $root.lang.mailTo }}
          vue-tags-input(
            v-model="mailToTmp"
            :tags.sync="mailToArr"
            autofocus
            :placeholder="$root.lang.mailToPlaceholder"
            autocomplete="false"
            @before-adding-tag="addMail"
            :required="mailToArr.length === 0"
          )
        .form-group
          label(for='from') {{ $root.lang.mailFrom }}
          input.form-control(
            type="email"
            id="from"
            :placeholder="$root.lang.mailFromPlaceholder"
            v-model="mail.from"
            required
          )
        .form-group
          label(for='message') {{ $root.lang.mailMessage }}
          textarea.form-control(id="message" :placeholder="$root.lang.mailMessage" v-model="mail.message")
        .checkbox
          label
            input#downloadNotification(type="checkbox" v-model="mail.downloadNotifications")
            | {{ $root.lang.mailDownloadNotification }}
        .form-group.text-right
          button#sendMailBtn.btn.btn-success(type="submit")
            icon.fa-fw(name="paper-plane")
            |  {{ $root.lang.mailSendBtn }}
      .panel-body(v-else)
        strong {{ $root.lang.mailsSent }}
</template>

<script type="text/babel">
import VueTagsInput from '@johmun/vue-tags-input';
import 'vue-awesome/icons/paper-plane';
import { mapGetters, mapState } from 'vuex';
import { httpPost } from "../common/util";

export default {
  name: 'SendMail',
  components: { VueTagsInput },

  data() {
    return {
      mail: {
        to: '',
        from: '',
        message: '',
        downloadNotifications: false
      },
      mailToTmp: '',
      mailToArr: [],
      sendSuccess: false
    }
  },

  computed: {
    ...mapState('upload', ['sid']),
    ...mapGetters('upload', ['shareUrl'])
  },

  methods: {
    addMail({ tag, addTag }) {
      const txt = tag.text;
      if(!txt.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        alert(this.$root.lang.mailInvalid);
      } else {
        addTag();
      }
    },
    async send() {
      this.mail.to = this.mailToArr.map(v => v.text).join(',');
      const data = {
        ...this.mail,
        sid: this.sid,
        lang: this.$root.lang.langCode,
        shareLink: this.shareUrl,
      }
      try {
        await httpPost('send-mail', data);
        this.sendSuccess = true;
      }
      catch (e) {
        alert(e);
      }
    }
  }
};
</script>

<style>
.vue-tags-input .ti-input {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-radius: 4px;
}
.vue-tags-input .ti-tag {
  background-color: #0275D8;
}
</style>
